import { Cancel, CheckBox, CheckCircle, Visibility, Add } from "@mui/icons-material"
import { Autocomplete, Badge, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DataTable from "../../components/tables/DataTable"
import CustomMonthPicker from "../../components/layouts/common/CustomMonthPicker"





// const PendingReimberesementUI = ({ listLoading,columns, filters, setFilters, list }) => {
//   const user = useSelector(state => state.user)
//   return (
//     <>
//       <Box mt={3} mb={3}>

//         <Paper elevation={2} sx={{ width: "100%" }} p={4} component={Box}>

//           <Box>
//             <Typography sx={{
//               fontWeight: 700,
//               fontSize: "24px",
//               lineHeight: "40px",
//               color: "#0D0D0D"
//             }}>{filters.date && filters.date.format('MMMM')} Expense</Typography>
//           </Box>
//           <Box sx={{ minHeight: "300px" }} mt={3}>
//           <DataTable columns={columns} rows={list?.result ?? []} count={list?.total} filters={filters} setFilters={setFilters} loading={listLoading} />
//         </Box>
//         </Paper>
//       </Box>
//     </>
//   )
// }


// export default PendingReimberesementUI

const PendingReimberesementUI = ({ listLoading, columns, filters, setFilters, list }) => {
  const user = useSelector(state => state.user);
  
  return (
    <>
      <Box mt={3} mb={3}>
        <Paper elevation={2} sx={{ width: "100%" }} p={4} component={Box}>
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "40px", color: "#0D0D0D" }}>
               Expense
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} mt={2}>
            <CustomMonthPicker date={filters.date} setDate={(date) => { setFilters({ ...filters, date }) }} />
          </Box>
          <Box sx={{ minHeight: "300px" }} mt={3}>
            <DataTable columns={columns} rows={list?.result ?? []} count={list?.total} filters={filters} setFilters={setFilters} loading={listLoading} />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default PendingReimberesementUI;