import { memo, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../../store/actions/commonAction'

import { openModal } from '../../../../store/actions/modalAction'


import Calendar from '../../../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'

import {
  CALENDAR_ITEM_TYPES,
  LEAVE_COLORS,
  LEAVE_RESPONSE,
  SNACK_BAR_VARIETNS,

} from '../../../../utils/constants'
import { fetchTasksCalendarApi } from '../../../../apis/task.api'
import moment from 'moment'
import { EmployeeTaskUserContext } from '../../EmployeeMain'
import CustomMonthPicker from '../../../../components/layouts/common/CustomMonthPicker'
import { Box } from '@mui/material'
import TaskDetailsController from '../TaskDetails.controller'
import { callSnackBar } from '../../../../store/actions/snackbarAction'
import { getAllDailyUpdates } from '../../../../apis/dailyupdate.api'

const TaskCalendarController = ({ Component,isFromPerfomance }) => {
  const { holiday, user } = useSelector((state) => state)
  const selectedUser = useContext(EmployeeTaskUserContext)
  const [calendarTimeObj, setCalendarTimeObj] = useState(moment())

  const weeklyOf = selectedUser ? selectedUser.weekly_of : user.data.weekly_of
  const userId = selectedUser ? selectedUser._id : user.data._id

  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)

  const holidayList = holiday.data
  const [taskList, setTaskList] = useState(null)

  ///for daily updates
  const [updatesList, setUpdateList] = useState(null)

  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    userId: userId,
    isFromPerfomance
  })

  const fetchList = () => {
    setListLoading(true)


    const parmasToPass = { ...filters }

    dispatch(
      callApiAction(
        async () =>
          await fetchTasksCalendarApi({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {
          
          setTaskList(response)
          
        },
        (err) => {
          setTaskList(null)
          dispatch(callSnackBar("server Error",SNACK_BAR_VARIETNS.error))
        },
      ),
    )

    dispatch(
      callApiAction(
        async () =>
          await getAllDailyUpdates({
            ...parmasToPass,
            userId,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          
          setUpdateList(response)
          
        },
        (err) => {
          setUpdateList(null)
          dispatch(callSnackBar("server Error",SNACK_BAR_VARIETNS.error))
        },
      ),
    )


  }

  useEffect(() => {
    fetchList()
  }, [calendarTimeObj.valueOf(),userId])

  useEffect(() => {
    if (taskList && updatesList) {
      let temp = []
      taskList.forEach((item)=>{
        temp.push({
          title: item.name,
          completed_date:item.completed_date? new Date(item.completed_date):null,
          start: new Date(item.date),
          end: new Date(item.date),
          relatedId: item._id,
          type: CALENDAR_ITEM_TYPES.task,
          status:item.status,
          priority:item.priority,
          onClick: () => { dispatch(openModal({title:"Task Detail",component:<TaskDetailsController callBack={()=>{}} selectedUser={selectedUser} id={item._id}  />,size:"md"})) },
        })
      })


      updatesList.forEach((item)=>{
        temp.push({
          title: item.count,
          dailyUpdate:true,          
          start: new Date(item.date),
          end: new Date(item.date),          
          type: CALENDAR_ITEM_TYPES.daily_updates                  
        })
      })
      
      if (holidayList && Array.isArray(holidayList)) {
        holidayList.forEach((leave) => {
          temp.push({
            title: leave.name,
            start: new Date(leave.date),
            end: new Date(leave.date),
            relatedId: leave._id,
            type: CALENDAR_ITEM_TYPES.holiday,
            color: LEAVE_COLORS.HOLIDAY,
            onClick: () => { },
          })
        })
      }
     

      setList(temp)
      setListLoading(false)
    }
  }, [taskList,updatesList])



  return (
    <>
      <Box mb={4}>
      <CustomMonthPicker date={calendarTimeObj} setDate={setCalendarTimeObj} />
      </Box>
      <Calendar
        loading={listLoading}
        data={list}
        dayComponent={(props) => Component?<Component weeklyOf={weeklyOf ?? [0]}  {...props} />: <CalendarBox weeklyOf={weeklyOf ?? [0]}  {...props} />}
        calendarTimeObj={calendarTimeObj.toDate()}
      />

    </>
  )
  return <></>
}
export default memo(TaskCalendarController)

