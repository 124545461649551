
import React, { memo, useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; import TaskIcon from '@mui/icons-material/Assignment';

import { Delete } from '@mui/icons-material';

import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';




const PmsCreateUi = ({ formData, handleChange, handleSubmit, addNewField, deleteField, loading, readOnlyFields }) => {
  const currentDate = moment().startOf('day'); // Disable past dates


  return (
    <Box>
      <Box display={"flex"} width={"98%"} height={"10vh"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Box ml={2} display={"flex-start"}>
          <Typography sx={{ fontSize: "22px", fontWeight: "bold", color: "primary" }}>
            Monthly Evaluation Form
          </Typography>
        </Box>
        <Box>
          <Button color="primary" variant='contained' sx={{ width: "7vw", height: "5vh", display: "flex", justifyContent: "center" }} onClick={addNewField}>
            <AddIcon sx={{ fontSize: "30px" }} />
            <Typography>ADD</Typography>
          </Button>
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Monthly Task</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Target Completion Date</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Action</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.tasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: '70%' }}>
                    <TextField
                      name="monthly_task"
                      value={task.monthly_task}
                      onChange={(e) => handleChange(index, 'monthly_task', e.target.value)}
                      fullWidth
                      multiline
                      required={index === 0}  // Only first task is required
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <DesktopDatePicker
                      label="Date"
                      inputFormat="DD/MM/YYYY"
                      value={task.completion_date}
                      onChange={(date) => handleChange(index, 'completion_date', date)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={false} // Ensure error prop is false
                        />
                      )}
                      disablePast
                    />
                  </TableCell>
                  <TableCell style={{ width: '10%', textAlign: 'center' }}>
                    <IconButton
                      color="error"
                      onClick={() => deleteField(index)}
                      disabled={index === 0}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: "10.5vw", height: "7vh", fontSize: '20px' }}  // Adjust the width and height as needed
          >
            Submit
          </Button>
        </Box>

      </form>
    </Box>
  );
};

export default PmsCreateUi;