import {
  People,
  PriceChangeOutlined,
  Tour,
  Diversity1,
  Campaign,
  HistoryEdu,
  FactCheck,
} from "@mui/icons-material";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Box, Paper } from "@mui/material";
import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";
import EmployyeLeavesController from "./pages/leaves/EmployyeLeavesController";
import CoffDetailsController from "./pages/leaves/leaveSummary/CoffDetailsController";
import LeaveDetailsController from "./pages/leaves/leaveSummary/LeaveDetailsController";
import MyLeaveController from "./pages/leaves/MyLeaveController";
import ProfileController from "./pages/profile/ProfileController";
import EmployeeReimberesementController from "./pages/reimberesement/EmployeeReimberesementController";
import ReimberesementController from "./pages/reimberesement/ReimberesementController";
import ReimberesementDetailsController from "./pages/reimberesement/ReimberesementDetailsController";
import SignInController from "./pages/signin/SignInController";
import UserController from "./pages/user/UserController";
import { USER_ROLES } from "./utils/constants";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ArticleIcon from "@mui/icons-material/Article";
import LeaveRaiseController from "./pages/leaves/leaveRaise/LeaveRaiseController";
import CoffRaiseController from "./pages/leaves/leaveRaise/CoffRaiseController";
import SummaryMainDialog from "./pages/leaves/leaveSummary/SummaryMainDialog";
import ReimberesementCreateController from "./pages/reimberesement/ReimberesementCreateController";
import SettingsIcon from "@mui/icons-material/Settings";
import HolidayController from "./pages/Holiday/HolidayController";
import BranchController from "./pages/branch/BranchController";
import DepartmentController from "./pages/department/DepartmentController";
import EmployeeTypeController from "./pages/employeetype/Type/EmployeeTypeController";
import GenerateSalarySlip from "./pages/payrollSummary/GenerateSalarySlipUi";
import AnnouncementsFormController from "./pages/announcements/AnnouncementsFormController";
import SalarySlipUI from "./pages/payrollSummary/SalarySlipUi";
import DailyupdateController from "./pages/tasks/DailyUpdate/DailyupdateController";
import TaskMain from "./pages/tasks/TaskMain";
import UserCreateController from "./pages/user/UserCreateController";
import EmployeeMain from "./pages/tasks/EmployeeMain";
import TaskCreateController from "./pages/tasks/TaskTimeline/TaskCreateController";
import TaskListController from "./pages/tasks/TaskTimeline/TaskListController";
// import TaskCalendarController from './pages/tasks/TaskTimeline/TaskCalendarController'
import TaskDetailsController from './pages/tasks/TaskTimeline/TaskDetails.controller'
import TaskCalendarController from './pages/tasks/TaskTimeline/calendar/TaskCalendarController'
import PendingMainContainer from './pages/leaves/leaveSummary/PendingMainContainer'
import PendingReimberesementController from './pages/reimberesement/PendingReimberesementController'
import MyVisit from './pages/visits/MyVisit'
import EmployeeVisits from './pages/visits/EmployeeVisits'
import VersionsController from './pages/versions/VersionsController'
import LeaveTypesController from './pages/leaveType/LeaveTypeController';
import ManualLeaveRaiseController from './pages/leaves/manual-leaves/ManualLeaveRaiseController'
import DocumentssController from './pages/companyDocuments/DocumentsController'
import AdvancePaymentsController from './pages/advancePayments/AdvancePaymentsController'
import WFHRaiseController from './pages/wfh/WFHRaiseController'

import DailyUpdateReportController from './pages/tasks/DailyUpdate/DailyUpdateReportController'
import EmployeeSalaryStrcutureController from './pages/salarystructure/EmployeeSalaryStrcutureController'
import VisitCalendarController from './pages/visits/VisitCalendarController'
import { VISIT_TYPES } from './utils/visits.constants'
import VisitPlacesListController from './pages/visits/visitedplaces/VisitPlacesListController'

import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardController from './pages/dashboard/DashboardController';
import TeamDetailsController from './pages/dashboard/TeamDetailsController';
import FeedbackTypeController from './pages/feedbacktype/FeedbackTypeController';
import FeedbackFormController from './pages/feedbackForm/FeedbackFormController';
import ForwardLeaveController from "./pages/leaves/forwardLeave/ForwardLeaveController";
import GenerateAppresialLetterUi from "./pages/appresialLetter/GenerateAppresialLetterUi";
import ApprasialLetterUi from "./pages/appresialLetter/ApprasialLetterUI";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GenerateForm16UI from "./pages/form16/GenerateForm16Ui";
import Form16UI from "./pages/form16/Form16UI";
import HandshakeIcon from '@mui/icons-material/Handshake';
import GenerateFinalSettlementUi from "./pages/fullandfinalsettlement/GenerateFinalSettlementUi";
import EmployeeEvaluationTableController from "./pages/employeeEvaluation/EmployeeEvaluationTableController";
import UploadFileIcon from '@mui/icons-material/UploadFile';

import LeaveEncashmentController from "./pages/leaves/leaveEncashment/LeaveEncashmentController";
import EmployeeTaskReviewController from "./pages/pms/EmployeeTaskReviewController"
import PmsCreateController from "./pages/pms/PmsCreateController"
import PmsPdfController from "./pages/pms/PmsPdfController"

//import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GenerateForm16Ui from "./pages/form-16/GenerateForm16Ui";

const defineRoutes = (user) => {

  const companyName = "WeHear";

  return [
    {
      path: "sign-in",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={user?.data?.role == USER_ROLES.destributor ? "/tasks/employee-tasks" : "/tasks/my-tasks/"} />
      ),
      hideInPannel: true,
    },

    {
      path: "profile",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "Profile",
          element: <ProfileController />,
        },
      ],
      hideInPannel: true,
    },
    {
      // hideInMobile:true,
      path: "dashboard",
      icon: <DashboardIcon />,
      title: "Dashboard",
      element: user.isLoggedIn ? (
        <AppContainer >
          <DashboardController />
        </AppContainer>
      ) : (
        <Navigate replace to="/sign-in" />
      )




    },
   
    {
      path: "tasks",
      icon: <PieChartOutlineIcon />,
      title: "Task Management",
      mobileTitle: "Task",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "My Tasks",
          element: <TaskMain />,
          hideInPannel: true,
          children: [
            {
              path: "",
              title: "My Tasks",
              element: <TaskCalendarController />,
            },
            {
              path: "create",
              title: "Create Task",
              element: <TaskCreateController />,
            },
            {
              path: "calendar",
              title: "My Tasks",
              element: <TaskCalendarController />,
            },
            {
              path: "calendar/daily-update/:time",
              title: "daily-update",
              element: <DailyupdateController />,
            },

            {
              path: "task-timeline",
              title: "task-timeline",
              element: <TaskListController />,
              hideInPannel: true,
            },

            {
              path: "daily-update",
              title: "daily-update",
              element: <DailyupdateController />,
              hideInPannel: true,
            },
            {
              path: "daily-update-report",
              title: "daily-update-report",
              element: <DailyUpdateReportController />,
              hideInPannel: true,
            }
          ],
        },
        {
          path: "detail/:id",
          title: "Task Details",
          hideInPannel: "true",
          element: (
            <Paper component={Box} p={3} mt={3}>
              <TaskDetailsController />
            </Paper>
          ),
        },
        {
          path: "create-task",
          title: "Create Task",
          element: (
            <Paper component={Box} p={3} mt={3}>
              <TaskCreateController />
            </Paper>
          ),
        },
        {
          path: "my-tasks",
          title: "My Tasks",
          element: <TaskMain />,
          hideInPannel: user?.data?.role == USER_ROLES.destributor,
          children: [
            {
              path: "",
              title: "My Tasks",
              element: <TaskCalendarController />,
            },
            {
              path: "calendar",
              title: "My Tasks",
              element: <TaskCalendarController />,
            },
            {
              path: "calendar/daily-update/:time",
              title: "daily-update",
              element: <DailyupdateController />,
            },
            {
              path: "task-timeline",
              title: "task-timeline",
              element: <TaskListController />,
              hideInPannel: true,
            },

            {
              path: "daily-update",
              title: "daily-update",
              element: <DailyupdateController />,
              hideInPannel: true,
            },
            {
              path: "daily-update-report",
              title: "daily-update-report",
              element: <DailyUpdateReportController />,
              hideInPannel: true,
            }
          ],
        },

        // {
        //   path: 'pending',
        //   title: 'General Tasks',

        //   element: <GeneralTaskController />,
        //   hideInPannel: false,

        // },
        {
          path: "employee-tasks",
          title: "Employee Tasks",
          element: <EmployeeMain />,
          hideInPannel: false,
          children: [
            {
              path: "",
              title: "My Tasks",
              element: <TaskCalendarController />,
            },
            {
              path: "calendar",
              title: "My Tasks",
              element: <TaskCalendarController />,
            },
            {
              path: "calendar/daily-update/:time",
              title: "daily-update",
              element: <DailyupdateController />,
            },
            {
              path: "task-timeline",
              title: "task-timeline",
              element: <TaskListController />,
              hideInPannel: true,
            },
            {
              path: "daily-update",
              title: "daily-update",
              element: <DailyupdateController />,
              hideInPannel: true,
            },
            {
              path: "daily-update/:time",
              title: "daily-update",
              element: <DailyupdateController />,
              hideInPannel: true,
            },
            {
              path: "daily-update-report",
              title: "daily-update-report",
              element: <DailyUpdateReportController />,
              hideInPannel: true,
            }
          ]
        },
        {
          path: "",
          hideInPannel: true,
          element: <Navigate to={"/tasks/my-tasks/"} />,
        },
      ],
    },

//monthly evolution task 
{
  path: "pms",
  icon: <FactCheck />,
  title: "Monthly Task Management",
  mobileTitle: "Task",
  element: user.isLoggedIn ? (
    <AppContainer />
  ) : (
    <Navigate replace to="/sign-in" />
  ),
  children: [
    {
      path: "",
      title: "My Tasks",
      element: <TaskMain />,
      hideInPannel: true,
      children: [
        {
          path: "",
          title: "Employee Task Review ",
          element:<Paper sx={{ mt: 3 }}> <EmployeeTaskReviewController /></Paper>,
        },
        {
          path: "create Pms",
          title: "Pms Task",
          element: <PmsCreateController />,
        },
        {
          path: "calendar",
          title: "My Tasks",
          element: <TaskCalendarController />,
        },
        {
          path: "calendar/daily-update/:time",
          title: "daily-update",
          element: <DailyupdateController />,
        },

        {
          path: "task-timeline",
          title: "task-timeline",
          element: <TaskListController />,
          hideInPannel: true,
        },

        {
          path: "daily-update",
          title: "daily-update",
          element: <DailyupdateController />,
          hideInPannel: true,
        },
        {
          path: "daily-update-report",
          title: "daily-update-report",
          element: <DailyUpdateReportController />,
          hideInPannel: true,
        }
      ],
    },
   
    {
      path: "Monthly-Evaluation-Form",
      title: "Monthly Evaluation Form",
      element: (
        <Paper component={Box} p={3} mt={3}>
          <PmsCreateController />
        </Paper>
      ),
    },
    {
      path: "Employee-Task-Review",
      title: "Employee Task Review ",
      children: [
        {
          path: "",
          title: "Employee Task Review",
          element: <EmployeeTaskReviewController />,
    },
      ],
    },

    // {
    //   path: 'pending',
    //   title: 'General Tasks',

    //   element: <GeneralTaskController />,
    //   hideInPannel: false,

    // },
    {
      path: "Monthly-task-Evaluation-PDF",
      title: "Monthly Task Evaluation Pdf",
      element:   <PmsPdfController />,
      hideInPannel: !(
        user.data.role === USER_ROLES.hr || user.data.role === USER_ROLES.admin
      ),
      children: [
        {
          path: "",
          title: "Monthly Task Evolution Pdf",
          
          element: <PmsPdfController />,
    },
      ]
    },
   
  ],
},

    {
      path: "calendar",
      icon: <EventAvailableIcon />,
      title: "Leave Calendar",
      mobileTitle: "Leave",
      element: user.isLoggedIn ? (
        <AppContainer key={"2"} />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: '',
          title: 'My Calendar',
          hideInPannel: user?.data?.role == USER_ROLES.destributor,
          children: [
            {
              path: "",
              title: "My Calendar",
              element: <MyLeaveController />,
            },

            {
              path: "leave/id/:id",
              title: "My Calendar",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <LeaveDetailsController />
                </Paper>
              ),
            },
            {
              path: "c-off/id/:id",
              title: "My Calendar",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <CoffDetailsController />
                </Paper>
              ),
            },
            {
              path: "rais-leave",
              title: "Leave Raise",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <LeaveRaiseController />
                </Paper>
              ),
              hideInPannel: true,
            },
            {
              path: "raise-wfh",
              title: "Raise WFH",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <WFHRaiseController />
                </Paper>
              ),
              hideInPannel: true,
            },
            {
              path: "comp-off",
              title: "Comp Off",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <CoffRaiseController />
                </Paper>
              ),
              hideInPannel: true,
            },
            {
              path: "summary",
              title: "Summary",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <SummaryMainDialog />
                </Paper>
              ),
              hideInPannel: true,
            },
          ],
        },
        {
          path: "pending",
          title: "Pending Actions",
          element: (
            <Paper sx={{ mt: 3 }}>
              <PendingMainContainer />
            </Paper>
          ),
        },
        {
          path: "employee",
          title: "Employee Calendar",
          element: <EmployyeLeavesController />,
        }
      ],
    },
    {
      path: "visit",
      icon: <Tour />,

      hideInMobile: true,
      title: "Visits",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "My Visits",
          element: <MyVisit />,
          hideInPannel: user?.data?.role == USER_ROLES.destributor,
          children: [
            {
              path: '',
              title: 'Calendar',
              element: <VisitCalendarController />,
            },
            {
              path: 'store',
              title: 'Store',
              element: <VisitPlacesListController category={VISIT_TYPES.STORE} key={VISIT_TYPES.STORE} />,
            },
            {
              path: 'doctor',
              title: 'AudioLogist',
              element: <VisitPlacesListController category={VISIT_TYPES.DOCTOR} key={VISIT_TYPES.DOCTOR} />,
            }
          ]
        },

        {
          path: "employee",
          title: "Employee Visits",
          element: <EmployeeVisits />,
          children: [
            {
              path: '',
              title: 'Calendar',
              element: <VisitCalendarController forEmployee />,
            },
            {
              path: 'store',
              title: 'Store',
              element: <VisitPlacesListController forEmployee category={VISIT_TYPES.STORE} key={VISIT_TYPES.STORE} />,
            },
            {
              path: 'doctor',
              title: 'AudioLogist',
              element: <VisitPlacesListController forEmployee category={VISIT_TYPES.DOCTOR} key={VISIT_TYPES.DOCTOR} />,
            }
          ]
        },
      ],
    },
    {
      path: "reimburesement",
      icon: <PriceChangeOutlined />,
      title: "Reimburesement",
      mobileTitle: "Reimbursement",
      element: user.isLoggedIn ? (
        <AppContainer key={"2"} />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      // hideInPannel: user.data.role === USER_ROLES.employee,
      children: [
        {
          path: "advance-payments",
          title: "Advance Payments",
          hideInPannel: user.data.role === USER_ROLES.employee,
          element: <AdvancePaymentsController />,
        },
        {
          path: '',
          title: 'My Reimburesement',
          hideInPannel: user?.data?.role == USER_ROLES.destributor,
          children: [
            {
              path: "",
              title: "My Reimburesement",
              element: <ReimberesementController />,
            },
            {
              path: "apply-reimbursement",
              title: "Apply Reimbursement",
              element: (
                <Paper sx={{ mt: 3 }}>
                  <ReimberesementCreateController />
                </Paper>
              ),
              hideInPannel: true,
            },
          ],
        },
        {
          path: "pending",
          title: "Pending Actions",
          element: <PendingReimberesementController />,
        },
        {
          path: "employee",
          title: "Employee Reimburesement",
          element: <EmployeeReimberesementController />,
        },
        {
          path: "get/:id",
          title: "",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <ReimberesementDetailsController />{" "}
            </Paper>
          ),
          hideInPannel: true,
        },
      ],
    },

    {
      hideInMobile: true,
      path: 'salary',
      hideInPannel: user?.data?.role == USER_ROLES.destributor || user?.data?.role === USER_ROLES.employee ,
      icon: <ArticleIcon />,
      title: "Payroll Summary",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "My Salary Slip",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <SalarySlipUI />{" "}
            </Paper>
          ),
          hideInPannel: true,
        },
        {
          path: "generatesalary",
          title: "Generate Salary Slip",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <GenerateSalarySlip />{" "}
            </Paper>
          ),
          hideInPannel: !(
            user.data.role == USER_ROLES.hr ||
            user.data.role == USER_ROLES.admin
          ),
        },

        {
          path: "salary-structure",
          title: "Salary Structure",
          element: <EmployeeSalaryStrcutureController />,
          hideInPannel: true,
        },
      ],
    },
    

    {
      hideInMobile: true,
      path: 'appresial-letter',
      hideInPannel: user?.data?.role == USER_ROLES.destributor || user?.data?.role === USER_ROLES.employee ,
      icon: <HistoryEdu />,
      title: "Appresial Letter",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "My Apprasial Letter",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <ApprasialLetterUi/>{" "}
            </Paper>
          ),
          hideInPannel: true,
        },
        {
          path: "generateappresialletter",
          title: "Generate Appresial Letter",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <GenerateAppresialLetterUi/>{" "}
            </Paper>
          ),
          hideInPannel: !(
            user.data.role == USER_ROLES.hr ||
            user.data.role == USER_ROLES.admin
          ),
        },

        
        // {
        //   path: "salary-structure",
        //   title: "Salary Structure",
        //   element: <EmployeeSalaryStrcutureController />,
        //   hideInPannel: true,
        // },
      ],
    },

    {
      hideInMobile: true,
      path: 'form-16',
      hideInPannel: user?.data?.role == USER_ROLES.destributor || user?.data?.role === USER_ROLES.employee,
      icon: <InsertDriveFileIcon />,
      title: "Form-16",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "My Form-16",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <Form16UI/>{" "}
            </Paper>
          ),
          hideInPannel: true,
        },

        {
          path: "Uploadform16",
          title: "Upload Form-16",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <GenerateForm16UI />{" "}
            </Paper>
          ),
          hideInPannel: !(
            user.data.role == USER_ROLES.hr ||
            user.data.role == USER_ROLES.admin
          ),
        },

      ],
    },

    {
      hideInMobile: true,
      path: 'employee_evaluation',
      hideInPannel: user?.data?.role == USER_ROLES.destributor || user?.data?.role === USER_ROLES.employee,
      icon: <UploadFileIcon />,

      title: "Evaluation Form",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [

        {
          path: "EvaluationForm",
          title: "Evaluation Form",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <EmployeeEvaluationTableController />{" "}
            </Paper>
          ),
          hideInPannel: !(
            user.data.role == USER_ROLES.hr ||
            user.data.role == USER_ROLES.admin
          ),
        },

      ],
    },

    {
      hideInMobile: true,
      path: 'form-16',
      hideInPannel: user?.data?.role == USER_ROLES.destributor || user?.data?.role === USER_ROLES.employee ,
      icon: <InsertDriveFileIcon />,
      title: "Form-16",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "generateform",
          title: "Generate Form-16",
          element: (
            <Paper sx={{ mt: 3 }}>
              {" "}
              <GenerateForm16Ui />{" "}
            </Paper>
          ),
          hideInPannel: !(
            user.data.role == USER_ROLES.hr ||
            user.data.role == USER_ROLES.admin
          ),
        },
      ],
    },
    {
      hideInMobile: true,
      path: "Announcements",
      icon: <Campaign />,
      title: "Announcement",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: !(
        user.data.role === USER_ROLES.hr || user.data.role === USER_ROLES.admin
      ),
      children: [
        {
          path: "",
          title: "Announcements",
          element: (
            <Paper sx={{ mt: 3 }}>
              <AnnouncementsFormController />
            </Paper>
          ),
        },
      ],
    },

    {
      hideInMobile: true,
      path: "Feedback",
      icon: <AnnouncementIcon />,
      title: "Feedback",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: !(
        user.data.role === USER_ROLES.hr || user.data.role === USER_ROLES.admin
      ),
      children: [
        {
          path: "",
          title: "Feedback Data",
          element: (
            <Paper sx={{ mt: 3 }}>
              <FeedbackFormController />
            </Paper>
          ),
        },
        {
          path: "FeedbackType",
          title: "FeedbackType",
          element: (
            <Paper sx={{ mt: 3 }}>
              <FeedbackTypeController />
            </Paper>
          ),
        },
      ],
    },

    {
      hideInMobile: true,
      path: "users",
      icon: <People />,
      title: "Users",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      children: [
        {
          path: "",
          title: "User List",
          element: <UserController />,
        },
      
        {
          path: 'create',
          title: 'Create User',
          hideInPannel: !(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.hr),
          element: <Paper sx={{ mt: 3 }}><UserCreateController /></Paper>,
        },
      
      ],
    },

    {
      hideInMobile: true,
      path: "settings",
      icon: <SettingsIcon />,
      title: "Settings",
      element: user.isLoggedIn ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: !(
        user.data.role === USER_ROLES.hr || user.data.role === USER_ROLES.admin
      ),
      children: [
        {
          path: "manual-leave",
          title: "Manual Leave",
          element: (
            <Paper sx={{ mt: 3 }}>
              <ManualLeaveRaiseController />
            </Paper>
          ),
        },
        {
          path: "forward-leave",
          title: "Forward Leave",
          element: (
            <Paper sx={{ mt: 3 }}>
              <ForwardLeaveController />
            </Paper>
          ),
        },
        //leave encashment 
        // {
        //   path: "leave-encashment",
        //   title: "Leave Encashment",
        //   element: (
        //     <Paper sx={{ mt: 3 }}>
        //       <LeaveEncashmentController />
        //     </Paper>
        //   ),
        // },
        {
          path: "encash-leave",
          title: "Encash Leave",
          element: (
            <Paper sx={{ mt: 3 }}>
              <LeaveEncashmentController />
            </Paper>
          ),
        },
        {
          path: "holiday",
          title: "Holiday",
          element: (
            <Paper sx={{ mt: 3 }}>
              <HolidayController />
            </Paper>
          ),
        },

        {
          path: "document",
          title: "Document",
          element: (
            <Paper sx={{ mt: 3 }}>
              <DocumentssController />
            </Paper>
          ),
        },

        {
          path: "branch",
          title: "Branch",
          element: (
            <Paper sx={{ mt: 3 }}>
              <BranchController />
            </Paper>
          ),
        },
        {
          path: "department",
          title: "Department",
          element: (
            <Paper sx={{ mt: 3 }}>
              <DepartmentController />
            </Paper>
          ),
        },
        {
          path: "employee-type",
          title: "Employee Type",
          element: (
            <Paper sx={{ mt: 3 }}>
              <EmployeeTypeController />
            </Paper>
          ),
        },
        {
          path: "versions",
          title: "Versions",
          element: (
            <Paper sx={{ mt: 3 }}>
              <VersionsController />
            </Paper>
          ),
        },
        {
          path: "leave-types",
          title: "Leave Types",
          element: (
            <Paper sx={{ mt: 3 }}>
              <LeaveTypesController />
            </Paper>
          ),
        },
      ],
    },
    {
      hideInMobile:true,
      path: "team",

      icon: <Diversity1 />,
      title: companyName,
      element: user.isLoggedIn ? (
        <AppContainer >
          <TeamDetailsController />
        </AppContainer>
      ) : (
        <Navigate replace to="/sign-in" />
      )




    },

    {
      path: "*",
      element: !user.isLoggedIn ? (
        <Navigate replace to={"/sign-in"} />
      ) : (
        <Navigate replace to="/dashboard/" />
      ),
      hideInPannel: true,
    }
  ];
};
export default defineRoutes;

/*    

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            hideInPannel:true, //for showing it onSide pannel or not
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    hideInPannel:true,
                }
            ],
        },




================================================================
================================================================


*/