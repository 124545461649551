import {
  Box,
  Button,
  Checkbox,
  Collapse,

  Grid,

  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton, { ResetButton } from '../../../components/button/SubmitButton'
import CustomInput from '../../../components/inputs/CustomInput'

import CustomRadioButtons from '../../../components/layouts/common/CustomRadioButtons'

import { useNavigate } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPES } from '../../../utils/task.constants'
import { UserSearchBarNormal } from '../../../components/inputs/SearchBar'



const TaskCreateUi = ({
  userId,
  onSubmit,
  loading,
  fields,
  setFields,
  disableDates,
}) => {
  const { holiday, user } = useSelector((state) => state)


  const navigate = useNavigate()

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box p={2} mt={1} component="form" onSubmit={onSubmit}>

      <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", flexDirection: desktop ? "row" : 'column' }}>
        <Box >
          <Typography align='left' variant='h2'>
            New Task
          </Typography>
          <Typography variant='h6' color="error">
            {fields.err}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant='h3' color="grey.main">
            Application Date:
          </Typography >
          <Typography variant='h3' ml={2}>
            {moment().format("DD MMMM YYYY")}
          </Typography>
        </Box>


      </Box>
      <Box sx={{ display: "flex", width: "100%" }} >
        <Box sx={{ display: "flex", flex: 4,maxWidth:"100%", flexDirection: "column" }}>
          <Typography variant='h4' mt={3}>
            Task Title:*
          </Typography>

          <CustomInput
            name="title"
            id="title"
            required

            helperText="Upto 30 Character"
            disabled={loading}
            value={fields.name}
            onChange={(e) => {
              if (!e.target.value || e.target.value.length <= 30)
                setFields({ ...fields, err: '', name: e.target.value })
            }
            }
            type="text"
            placeholder="Write Task Title..."

          />

          <Typography variant='h4' mb={2} mt={3}>
            Task Description:*
          </Typography>


          <Box sx={{ width: "100%"}}>
            <CKEditor
              config={{
                                placeholder: "Enter a Task...",
                                style: { height: "500px", minHeight: "500px" },
                                toolbar: { items:['p','heading','italic','bold','blockquote','link','table','undo','redo','numberedList','bulletedList']}
                                
                            }}
              name="description"
              id="description"
              placeholder="Write Task Description..."
              disabled={loading}
              editor={ClassicEditor}
              style={{ width: "100%" }}
              onInit={(editor) => {


              }}
              data={fields.description}
              onReady={(editor) => {

                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "min-width",
                    "0px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                setFields({ ...fields, err: '', description: data })
              }}
              onBlur={(event, editor) => { }}
              onFocus={(event, editor) => { }}
            />
          </Box>


          <Typography variant='h4' mb={2} mt={3}>
            Assigne To:*
          </Typography>

          <Box>

            <UserSearchBarNormal
              defaultParams={{ searchable: ['first_name', 'middle_name', 'last_name'] }}
              defaultOptions={[{ _id: user.data._id, name: "Self", profile_url: null, designation: user.data.designation }]}
              inputProps={{ placeholder: "Select Employee", multiple: true }}
              defaultVal={null}

              onUserChange={(newVal) =>
                setFields({
                  ...fields,
                  err: "",
                  assigned_to: newVal._id,
                })
              }
            />
          </Box>

          <Grid container rowSpacing={3} mt={3} columnSpacing={3}>
            <Grid item xs={12} md={6}>

              <Typography variant='h4'>
                Task Start Date:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.start_date}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['start_date'] = e
                    if (e.valueOf() >= fields['due_date'].valueOf()) {
                      changedVal['due_date'] = e
                    }
                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                />

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4' >
                Task Due Date:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} >
                <DesktopDatePicker
                  shouldDisableDate={disableDates}
                  renderInput={(props) => {
                    return <CustomInput {...props} sx={{ height: '100%' }} />
                  }}
                  inputFormat="DD-MM-yyyy"
                  disabled={
                    loading ||
                    !(holiday.data && Array.isArray(holiday.data)) ||
                    holiday.loading
                  }
                  value={fields.due_date}
                  onChange={(e) => {
                    const changedVal = {}
                    changedVal['due_date'] = e

                    setFields({ ...fields, err: '', ...changedVal })
                  }}
                  type="date"
                  minDate={fields.start_date}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} mt={3} columnSpacing={3}>
            <Grid item xs={12} md={6}>

              <Typography variant='h4'>
                Task Type:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} mt={3}>
                <CustomRadioButtons
                  options={Object.keys(TASK_TYPES).map((key) => ({ label: key, value: TASK_TYPES[key] }))}
                  value={fields.type}
                  onChange={(e) => {
                    setFields({ ...fields, type: e.target.value })
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4' >
                Task Priority:*
              </Typography>
              <Box sx={{ display: "flex", flex: "none" }} mt={3}>
                <CustomRadioButtons
                  options={Object.keys(TASK_PRIORITY).map((key) => ({ label: key, value: TASK_PRIORITY[key] }))}
                  value={fields.priority}
                  onChange={(e) => {
                    setFields({ ...fields, priority: e.target.value })
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {fields.type != TASK_TYPES.ONCE && <>
            <Typography variant='h4' mt={3}>
              Task Should Repeated Till:*
            </Typography>
            <Box sx={{ display: "flex", flex: "none" }}>
              <DesktopDatePicker
                shouldDisableDate={disableDates}
                renderInput={(props) => {
                  return <CustomInput {...props} sx={{ height: '100%' }} />
                }}
                minDate={fields.start_date}
                inputFormat="DD-MM-yyyy"
                disabled={
                  loading ||
                  !(holiday.data && Array.isArray(holiday.data)) ||
                  holiday.loading
                }
                value={fields.till_date}
                onChange={(e) => {
                  const changedVal = {}
                  changedVal['till_date'] = e

                  setFields({ ...fields, err: '', ...changedVal })
                }}
                type="date"
              />
            </Box>

          </>}

          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>

            <Box sx={{ display: "flex" }} mr={3}>
              <ResetButton
                loading={loading}
                type="reset"
                variant="text"
                onClick={() => { navigate("/tasks/my-tasks/") }}
                title="Cancel"
              >

              </ResetButton>
            </Box>
            <Box sx={{ display: "flex" }}>
              <SubmitButton

                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                title="Assign Task"
              >

              </SubmitButton>
            </Box>
          </Box>
        </Box>
        {!desktop && <Box sx={{ flex: 1 }}></Box>}


      </Box>
    </Box>
  )
}
export default TaskCreateUi

// import React, { memo, useState } from 'react';
// import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, MenuItem, Select, FormControl, InputLabel, Box, IconButton, useMediaQuery } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import TaskIcon from '@mui/icons-material/Assignment';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import StatusIcon from '@mui/icons-material/TrackChanges';
// import RemarksIcon from '@mui/icons-material/Comment';
// import DoneIcon from '@mui/icons-material/CheckCircle';
// import InProgressIcon from '@mui/icons-material/Sync';
// import NotStartedIcon from '@mui/icons-material/RemoveCircleOutline';
// import PendingIcon from '@mui/icons-material/HourglassEmpty';
// import AddIcon from '@mui/icons-material/Add';
// import { useTheme } from '@emotion/react';
// import CustomInput from '../../../components/inputs/CustomInput';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import SubmitButton from '../../../components/button/SubmitButton';
// import SelectInput from '@mui/material/Select/SelectInput';
// // const CreatePmsUI = () => {
// //     const [formData, setFormData] = useState({
// //         tasks: Array(5).fill({ monthlyTask: '', targetCompletionDate: '', status: '', remarks: '' })
// //     });

// //     const navigate = useNavigate();

// //     const handleChange = (index, e) => {
// //         const { name, value } = e.target;
// //         const tasks = [...formData.tasks];
// //         tasks[index] = { ...tasks[index], [name]: value };
// //         setFormData({ tasks });
// //     };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();
// //         const response = await fetch('/api/pms', {
// //             method: 'POST',
// //             headers: {
// //                 'Content-Type': 'application/json'
// //             },
// //             body: JSON.stringify(formData)
// //         });

// //         if (response.ok) {
// //             navigate('/employee-dashboard');
// //         } else {
// //             console.error('Failed to submit the form');
// //         }
// //     };

// //     const addNewField = () => {
// //         setFormData((prevState) => ({
// //             tasks: [...prevState.tasks, { monthlyTask: '', targetCompletionDate: '', status: '', remarks: '' }]
// //         }));
// //     };

// //     return (
// //         <Grid container justifyContent="center" style={{ marginTop: 20 }}>
// //           <Typography variant="h2" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
// //                     Employee Monthly Evaluation Form
// //                 </Typography>
// //             <Paper elevation={3} style={{ padding: '50px 70px', width: '100%' }}>
                
// //                 <form onSubmit={handleSubmit}>
// //                     <TableContainer component={Paper}>
// //                         <Table>
// //                             <TableHead>
// //                                 <TableRow>
// //                                     <TableCell align="center">
// //                                         <Box display="flex" alignItems="center" justifyContent="center">
// //                                             <TaskIcon style={{ marginRight: 8 }} /> 
// //                                             <Typography variant="h5" style={{ fontWeight: 'bold', color: 'primary' }}>Monthly Task</Typography>
// //                                         </Box>
// //                                     </TableCell>
// //                                     <TableCell align="center">
// //                                         <Box display="flex" alignItems="center" justifyContent="center">
// //                                             <DateRangeIcon style={{ marginRight: 8 }} /> 
// //                                             <Typography variant="h5" style={{ fontWeight: 'bold' }}>Date</Typography>
// //                                         </Box>
// //                                     </TableCell>
// //                                     <TableCell align="center">
// //                                         <Box display="flex" alignItems="center" justifyContent="center">
// //                                             <StatusIcon style={{ marginRight: 8 }} /> 
// //                                             <Typography variant="h5" style={{ fontWeight: 'bold' }}>Status</Typography>
// //                                         </Box>
// //                                     </TableCell>
// //                                     <TableCell align="center">
// //                                         <Box display="flex" alignItems="center" justifyContent="center">
// //                                             <RemarksIcon style={{ marginRight: 8 }} /> 
// //                                             <Typography variant="h5" style={{ fontWeight: 'bold' }}>Remarks</Typography>
// //                                         </Box>
// //                                     </TableCell>
// //                                 </TableRow>
// //                             </TableHead>
// //                             <TableBody>
// //                                 {formData.tasks.map((task, index) => (
// //                                     <TableRow key={index}>
// //                                         <TableCell style={{ width: '30%' }}>
// //                                             <TextField
// //                                                 name="monthlyTask"
// //                                                 value={task.monthlyTask}
// //                                                 onChange={(e) => handleChange(index, e)}
// //                                                 fullWidth
// //                                                 multiline
// //                                                 required
// //                                                 variant="outlined"
// //                                             />
// //                                         </TableCell>
// //                                         <TableCell style={{ width: '20%' }}>
// //                                             <TextField
// //                                                 name="Date"
// //                                                 type="date"
// //                                                 value={task.targetCompletionDate}
// //                                                 onChange={(e) => handleChange(index, e)}
// //                                                 fullWidth
// //                                                 required
// //                                                 InputLabelProps={{ shrink: true }}
// //                                                 variant="outlined"
// //                                             />
// //                                         </TableCell>
// //                                         <TableCell style={{ width: '15%' }}>
// //                                             <FormControl fullWidth variant="outlined">
// //                                                 <InputLabel>Status</InputLabel>
// //                                                 <Select
// //                                                     name="status"
// //                                                     value={task.status}
// //                                                     onChange={(e) => handleChange(index, e)}
// //                                                     label="Status"
// //                                                     required
// //                                                     style={{ color: task.status === 'completed' ? 'green' : task.status === 'in progress' ? 'orange' : task.status === 'on hold' ? 'purple' : 'gray' }}
// //                                                 >
// //                                                     <MenuItem value="pending" style={{ color: 'red' }}>
// //                                                         <PendingIcon style={{ marginRight: 8 }} />
// //                                                         Pending
// //                                                     </MenuItem>
// //                                                     <MenuItem value="in progress" style={{ color: 'orange' }}>
// //                                                         <InProgressIcon style={{ marginRight: 8 }} />
// //                                                         In Progress
// //                                                     </MenuItem>
// //                                                     <MenuItem value="on hold" style={{ color: 'purple' }}>
// //                                                         <NotStartedIcon style={{ marginRight: 8 }} />
// //                                                         On Hold
// //                                                     </MenuItem>
// //                                                     <MenuItem value="completed" style={{ color: 'green' }}>
// //                                                         <DoneIcon style={{ marginRight: 8 }} />
// //                                                         Completed
// //                                                     </MenuItem>
// //                                                 </Select>
// //                                             </FormControl>
// //                                         </TableCell>
// //                                         <TableCell style={{ width: '30%' }}>
// //                                             <TextField
// //                                                 name="remarks"
// //                                                 value={task.remarks}
// //                                                 onChange={(e) => handleChange(index, e)}
// //                                                 fullWidth
// //                                                 multiline
// //                                                 variant="outlined"
// //                                             />
// //                                         </TableCell>
// //                                     </TableRow>
// //                                 ))}
// //                             </TableBody>
// //                         </Table>
// //                     </TableContainer>
// //                     <Grid container justifyContent="center" style={{ marginTop: 20 }}>
// //                         <IconButton color="primary" onClick={addNewField}>
// //                             <AddIcon />
// //                         </IconButton>
// //                     </Grid>
// //                     <Grid container justifyContent="right" style={{ marginTop: 20 }}>
// //                         <Button type="submit" variant="contained" color="primary">
// //                             Submit
// //                         </Button>
// //                     </Grid>
// //                 </form>
// //             </Paper>
// //         </Grid>
// //     );
// // };

// // export default CreatePmsUI;


// const PmsCreateUi = ({
//   onSubmit,
//   loading,
//   fields,
//   setFields,
//   addField,
//   removeField,
// }) => {
//   const theme = useTheme();
//   const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  
//   return (
//     <Box p={2} mt={1} component="form" onSubmit={onSubmit}>
//       <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", flexDirection: desktop ? "row" : 'column' }}>
//         <Typography variant='h2' align='center'>
//           Create New PMS
//         </Typography>
//       </Box>
//       {fields.map((field, index) => (
//         <Box key={index} mb={2} display="flex" flexDirection="column">
//           <Typography variant='h5' mt={2} align="left">
//             {`Title ${index + 1}`}
//           </Typography>
//           <CustomInput
//             required
//             name={`title_${index}`}
//             value={field.title}
//             onChange={(e) => {
//               const newFields = [...fields];
//               newFields[index].title = e.target.value;
//               setFields(newFields);
//             }}
//             placeholder={`Title ${index + 1}`}
//           />
//           <Typography variant='h5' mt={2} align="left">
//             {`Description ${index + 1}`}
//           </Typography>
//           <CKEditor
//             editor={ClassicEditor}
//             data={field.description}
//             onChange={(event, editor) => {
//               const data = editor.getData();
//               const newFields = [...fields];
//               newFields[index].description = data;
//               setFields(newFields);
//             }}
//           />
//           <Typography variant='h5' mt={2} align="left">
//             {`Status ${index + 1}`}
//           </Typography>
//           <SelectInput
//             required
//             name={`status_${index}`}
//             value={field.status}
//             onChange={(e) => {
//               const newFields = [...fields];
//               newFields[index].status = e.target.value;
//               setFields(newFields);
//             }}
//           >
//             <MenuItem value="pending">Pending</MenuItem>
//             <MenuItem value="in_progress">In Progress</MenuItem>
//             <MenuItem value="on_hold">On Hold</MenuItem>
//             <MenuItem value="completed">Completed</MenuItem>
//           </SelectInput>
//           {index >= 5 && (
//             <IconButton onClick={() => removeField(index)}>
//               <RemoveCircleIcon color="error" />
//             </IconButton>
//           )}
//         </Box>
//       ))}
//       <Button
//         variant="outlined"
//         onClick={addField}
//         startIcon={<AddCircleIcon />}
//         sx={{ marginTop: 2 }}
//       >
//         Add Field
//       </Button>
//       <Box mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
//         <SubmitButton
//           loading={loading}
//           type="submit"
//           variant="contained"
//           color="primary"
//         >
//           Submit
//         </SubmitButton>
//       </Box>
//     </Box>
//   );
// };

// export default memo(PmsCreateUi);