import React from 'react';
import { Box, Paper, IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import moment from 'moment';
import CustomDatePicker from './CustomDatePicker';
import colorTheme from '../../../assets/css/theme/colorTheme';

const CustomYearPicker = ({ date, setDate }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        padding: 2,
        flex: 1,
        backgroundColor: colorTheme.palette.grey.calender,
        height: "100%",
        boxShadow: 'none',
        border: "1px solid " + colorTheme.palette.grey.calender
      }}
      component={Box}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          mr: 2,
          borderRight: '1px solid gray',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() =>
            setDate(moment().set('year', date.get('year') - 1))
          }
        >
          <KeyboardDoubleArrowLeft color="primary" />
        </IconButton>

        <Typography variant="h3">
          {date && date.format('YYYY')}
        </Typography>

        <IconButton
          onClick={() =>
            setDate(moment().set('year', date.get('year') + 1))
          }
          disabled={moment().get('year') <= date.get('year')}
        >
          <KeyboardDoubleArrowRight
            color={moment().get('year') <= date.get('year') ? 'grey' : 'primary'}
          />
        </IconButton>
      </Box>

      {/* Remove or modify this if you have a different implementation for year picker */}
      <CustomDatePicker date={date} setDate={setDate} />
    </Paper>
  );
}

export default CustomYearPicker;