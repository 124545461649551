// import { Box, Button, Typography} from "@mui/material"
// import {  Add } from "@mui/icons-material"

// import { useCallback } from "react";
// import { useDispatch } from "react-redux";
// import { openModal } from "../../store/actions/modalAction";
// import AddDocumentsController from "./AddDocumentsController";
// import DataTable from "../../components/tables/DataTable";

// const DocumentsUI = ({ setState, filters, setFilters, callBack, loading, state, columns }) => {
//     const dispatch = useDispatch();
//     const addholiday = useCallback(() => {
//         dispatch(openModal({
//             title: "Add Document",
//             component: <AddDocumentsController callBack={callBack} />, size: 'sm'
//         }))
//     }, []) 
//     return <>

//         <Box m={3}>
//             <Box>
//                 <Typography sx={{
//                     fontWeight: 700,
//                     fontSize: "24px",
//                     lineHeight: "40px",
//                     color: "#0D0D0D"
//                 }}>Documents</Typography>
//                 <Button onClick={addholiday} sx={{
//                     display: "flex",
//                     height: "100%",
//                     zIndex: 0,
//                     textTransform: "capitalize",
//                     boxShadow: "none",
//                     border: "1px solid #393939",
//                     width: "100%",
//                     height: "56px",
//                     ":hover": {
//                         boxShadow: "none"
//                     }
//                 }}>
//                     <Add color="primary" /> &nbsp;Add Document
//                 </Button>
//             </Box>
//             <Box sx={{ minHeight: "300px" }} mt={3}>
//                 <DataTable columns={columns} rows={state} count={state?.length??0} filters={filters} setFilters={setFilters} loading={loading} />
//             </Box>
//         </Box>
//     </>
// }
// export default DocumentsUI

import { Box, Button, Typography} from "@mui/material"
import {  Add } from "@mui/icons-material"

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AddDocumentsController from "./AddDocumentsController";
import DataTable from "../../components/tables/DataTable";

const DocumentsUI = ({ setState, filters, setFilters, callBack, loading, state, columns }) => {
    const dispatch = useDispatch();
    const addholiday = useCallback(() => {
        dispatch(openModal({
            title: "Add Document",
            component: <AddDocumentsController callBack={callBack} />, size: 'sm'
        }))
    }, []) 
    return <>

        <Box m={3}>
            <Box>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "40px",
                    color: "#0D0D0D"
                }}>Documents</Typography>
                <Button onClick={addholiday} sx={{
                    display: "flex",
                    height: "100%",
                    zIndex: 0,
                    textTransform: "capitalize",
                    boxShadow: "none",
                    border: "1px solid #393939",
                    width: "100%",
                    height: "56px",
                    ":hover": {
                        boxShadow: "none"
                    }
                }}>
                    <Add color="primary" /> &nbsp;Add Document
                </Button>
            </Box>
            <Box sx={{ minHeight: "300px" }} mt={3}>
                <DataTable columns={columns} rows={state} count={state?.length??0} filters={filters} setFilters={setFilters} loading={loading} />
            </Box>
        </Box>
    </>
}
export default DocumentsUI