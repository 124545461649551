
import { useDispatch } from "react-redux";

import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { getPmsDelete, getPmsUpdate } from "../../apis/pms.api";
import { callSnackBar } from "../../store/actions/snackbarAction";


import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const PmsEditForm = ({ formData, onSubmit }) => {
  const [form, setForm] = useState({ tasks: [] });
  const dispatch = useDispatch();

  useEffect(() => {
    setForm(formData || { tasks: [] });
  }, [formData]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const tasks = [...form.tasks];
    tasks[index] = { ...tasks[index], [name]: value };
    setForm({ ...form, tasks });
  };

  const handleDateChange = (index, date) => {
    const tasks = [...form.tasks];
    tasks[index] = { ...tasks[index], completion_date: date };
    setForm({ ...form, tasks });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = {
        id: form._id,
        tasks: form.tasks.map((task) => ({
          _id: task._id,
          monthly_task: task.monthly_task, // Add this line
          completion_date: task.completion_date,
          status: task.status ? parseInt(task.status) : '',
          remarks: task.remarks,
        })),
      };

      const response = await getPmsUpdate(updateData);
      if (response.status !== 1) {
        throw new Error('Failed to update form');
      }

      onSubmit(form);
    } catch (error) {
      console.error('Error updating form:', error);
      dispatch(callSnackBar('Failed to update form', 'error'));
    }
  };

  const deleteField = async (index) => {
    const taskToDelete = form.tasks[index];
    if (taskToDelete._id) {
      try {
        await getPmsDelete({ id: taskToDelete._id });
        dispatch(callSnackBar('Task deleted successfully', 'success'));

        const updatedTasks = form.tasks.filter((_, i) => i !== index);
        setForm({ ...form, tasks: updatedTasks });
      } catch (error) {
        console.error('Error deleting task:', error);
        dispatch(callSnackBar('Failed to delete task', 'error'));
      }
    } else {
      const updatedTasks = form.tasks.filter((_, i) => i !== index);
      setForm({ ...form, tasks: updatedTasks });
    }
  };

  return (
    <Grid
      container
      p={2}
      mt={5}
      display={'flex'}
      justifyContent="center"
      alignItems={'center'}
      maxHeight={'90vh'}
      sx={{ marginTop: 20, width: '100%' }}
    >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: '40%' }}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Monthly Task
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center" style={{ width: '20%' }}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Target Completion Date
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center" style={{ width: '20%' }}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Status
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center" style={{ width: '20%' }}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Remarks
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '10%', textAlign: 'center' }}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form.tasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: '40%' }}>
                    <TextField
                      name="monthly_task"
                      value={task.monthly_task}
                      fullWidth
                      multiline
                      required
                      variant="outlined"
                      onChange={(e) => handleChange(index, e)}
                    />
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <DesktopDatePicker
                      label="Date"
                      inputFormat="DD/MM/YYYY"
                      value={task.completion_date ? new Date(task.completion_date) : null}
                      onChange={(date) => handleDateChange(index, date)} // Use handleDateChange here
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          required
                          error={false}
                        />
                      )}
                      disablePast
                    />

                  </TableCell>

                  <TableCell style={{ width: '20%' }}>
                    <FormControl variant="outlined" fullWidth required>
                      <InputLabel>Status</InputLabel>
                      <Select
                        name="status"
                        value={task.status ? parseInt(task.status) : ''}
                        onChange={(e) => handleChange(index, e)}
                        label="Status"
                      >
                        <MenuItem value={1}>Pending</MenuItem>
                        <MenuItem value={2}>In Progress</MenuItem>
                        <MenuItem value={3}>On Hold</MenuItem>
                        <MenuItem value={4}>Completed</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>
                    <TextField
                      name="remarks"
                      value={task.remarks}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      multiline
                      variant="outlined"
                      required
                    />
                  </TableCell>
                  <TableCell style={{ width: '5%', textAlign: 'center' }}>
                    <IconButton color="error" onClick={() => deleteField(index)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          id="form-submit-button"
          style={{ display: 'none' }}
        >
          Submit
        </Button>
      </form>
    </Grid>
  );
};

export default PmsEditForm;