import axios from "axios";
import { getFileHeaders } from "../utils/helper";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const fetchevalutionApi =async(params)=>{
    return await axios({
        url: endpoints.fetchevalution,
        method: "GET",
        headers: getHeaders(),
        params,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const createevalutionApi =async(data)=>{
  const callResponse = await axios({
    url: endpoints.evalution,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }))

  return callResponse        
}




export const DeleteevalutionApi =async(data)=>{
    return await axios({
        url: endpoints.deleteevalution,
        method: "delete",
        headers: getHeaders(),
        data,
      })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));          
}

export const getevalutionByIdApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.evalutionbyId,
      method: "GET",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => ({status:0,response:err.response,code:err.response.status}))
  
    return callResponse
  }