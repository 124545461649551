import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { useParams } from "react-router-dom"
import { openModal } from "../../store/actions/modalAction"
import { getPendingReimberesementApi, getReimberesementApi, getReimberesementCountApi, updateReimberesementField } from "../../apis/reimbersement.api"
import MessageDilog from "../../components/MessageDilog"
import PendingReimberesementUI from "./PendingReimberesementPageUI"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { REIMBURSEMENT_STATUS, SNACK_BAR_VARIETNS } from "../../utils/constants"
import moment from "moment"
import { Box, Chip } from "@mui/material"
import ReimburesmentViewMoreButton from "./actionButtons/ReimburesementViewMoreButton"
import ReimburesementApprovalButton from "./actionButtons/ReimburesmentApprovalButtons"
import { findObjectKeyByValue, toTitleCase } from "../../utils/helper"
import { fetchPendingReimbursementDataAction } from "../../store/actions/reimbursementAction"

// const PendingReimberesementController = ({ userId }) => {

//     const [list, setList] = useState({})





//     const [listLoading, setListLoading] = useState(false)
//     const {reimbursement} = useSelector((state)=> state) 

//     const dispatch = useDispatch()
//     const params = useParams()
//     const columns = useMemo(() => [
//         { id: 1, fieldName: 'expenseDate', label: 'Expense Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.expenseDate).format("DD/MM/YYYY") },
        
//         { id: 2, fieldName: 'type', label: 'Expense Type', align: "left",minWidth: '130px', renderValue: (params) => toTitleCase(params.type) },
//         { id: 3, fieldName: 'amount', label: 'Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.amount },
//         { id: 1, fieldName: 'userId', label: 'Applied By', align: "left",  minWidth: '150px', renderValue: (params) => (params.userId?.first_name+" "+params.userId?.last_name) },
//         { id: 4, fieldName: 'purpose', label: 'Purpose', align: "left" },
//         {
//             id: 5, fieldName: 'status', label: 'Status', align: "left", renderValue: (params) => <Chip
//                 size="small"
//                 label={findObjectKeyByValue(params.status, REIMBURSEMENT_STATUS)}
//                 color={params.status == REIMBURSEMENT_STATUS.approved ? "success" : (params.status == REIMBURSEMENT_STATUS.rejected ? "error" : "warning")}
//             />,
//             sort: true
//         },
//         {
//             id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
//                 <ReimburesmentViewMoreButton id={params._id} />
//                 <ReimburesementApprovalButton params={params} setParams={setParams} />
//             </Box>
//         },
//     ], [dispatch]);

//     const [filters, setFilters] = useState({
//         pageNo: 1,
//         pageSize: 10,
//         search: '',
//         userId: userId ?? '',

//     })

//     const fetchList = () => {
//         setListLoading(true)
//         const passFilters = { ...filters }
//         dispatch(callApiAction(
//             async () => await getPendingReimberesementApi({ ...passFilters }),
//             (response) => {
//                 setList(response)
//                 setListLoading(false)
//             },
//             (err) => {
//                 setListLoading(false)
//             }
//         ))
//     }


//     const getPendingReimbursementList = () => {
//         if (!reimbursement.pending_data || reimbursement.pending_data.length === 0 || JSON.stringify(filters)!=JSON.stringify(reimbursement.pending_filters)) {
//             dispatch(fetchPendingReimbursementDataAction(filters));
//         }
//     }
//     useEffect(() => {
//         getPendingReimbursementList()
//     }, [filters])
//     // useEffect(() => {
//     //     fetchList()
//     // }, [filters])

   
//     return (
//         <>
//             <PendingReimberesementUI
//                 columns={columns}

//                 listLoading={reimbursement.pending_loading}
//                 filters={filters}
//                 setFilters={setFilters}
//                 list={reimbursement.pending_data}
//                 isForEmployee={userId}

//             />

//         </>
//     )
// }
// export default PendingReimberesementController


const PendingReimberesementController = ({ userId }) => {
    const [list, setList] = useState({});
    const [listLoading, setListLoading] = useState(false);
    const { reimbursement } = useSelector((state) => state);
    const dispatch = useDispatch();
    const params = useParams();

    const columns = useMemo(() => [
        { id: 1, fieldName: 'expenseDate', label: 'Expense Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.expenseDate).format("DD/MM/YYYY") },
        { id: 2, fieldName: 'type', label: 'Expense Type', align: "left", minWidth: '130px', renderValue: (params) => toTitleCase(params.type) },
        { id: 3, fieldName: 'amount', label: 'Amount', align: "left", sort: true, renderValue: (params) => "₹" + params.amount },
        { id: 1, fieldName: 'userId', label: 'Applied By', align: "left", minWidth: '150px', renderValue: (params) => (params.userId?.first_name + " " + params.userId?.last_name) },
        { id: 4, fieldName: 'purpose', label: 'Purpose', align: "left" },
        { id: 5, fieldName: 'status', label: 'Status', align: "left", renderValue: (params) => <Chip size="small" label={findObjectKeyByValue(params.status, REIMBURSEMENT_STATUS)} color={params.status == REIMBURSEMENT_STATUS.approved ? "success" : (params.status == REIMBURSEMENT_STATUS.rejected ? "error" : "warning")} />, sort: true },
        {
            id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <Box sx={{ display: "flex" }}>
                <ReimburesmentViewMoreButton id={params._id} />
                <ReimburesementApprovalButton params={params} setParams={setParams} />
            </Box>
        },
    ], [dispatch]);

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        userId: userId ?? '',
        date: moment(), // Add date filter
    });

    const fetchList = () => {
        setListLoading(true);
        const passFilters = { ...filters };
        passFilters.date = passFilters.date.toISOString(); // Convert date to ISO string

        dispatch(callApiAction(
            async () => await getPendingReimberesementApi(passFilters),
            (response) => {
                setList(response);
                setListLoading(false);
            },
            (err) => {
                setListLoading(false);
            }
        ));
    };

    const getPendingReimbursementList = () => {
        if (!reimbursement.pending_data || reimbursement.pending_data.length === 0 || JSON.stringify(filters) !== JSON.stringify(reimbursement.pending_filters)) {
            dispatch(fetchPendingReimbursementDataAction(filters));
        }
    };

    useEffect(() => {
        getPendingReimbursementList();
    }, [filters]);

    return (
        <>
            <PendingReimberesementUI
                columns={columns}
                listLoading={reimbursement.pending_loading}
                filters={filters}
                setFilters={setFilters}
                list={reimbursement.pending_data}
                isForEmployee={userId}
            />
        </>
    );
};

export default PendingReimberesementController;