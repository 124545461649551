import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import DocumentsUi from "./DocumentsUi";
import { Box } from "@mui/material";
import { fetchVersiones } from "../../apis/version.api";

import FileDownloadComponent from "../../components/FileDownloadComponent";
import { fetchdocuments } from "../../apis/document.api";
import DeleteDocumentButton from "./DeleteDocumentButton";
import { fetchDocumentDataAction } from "../../store/actions/settingsAction";

const DocumentssController = ({ userId }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state);

  const fetchVersionsApi = fetchVersiones;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        minWidth: "150px",
      },

      {
        id: 4,
        fieldName: "url",
        label: "Document",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) => <FileDownloadComponent src={params.url} />,
      },

      {
        id: 3,
        fieldName: "action",
        label: "Action",
        align: "left",
        renderValue: (params, setParams) => (
          <Box sx={{ display: "flex" }}>
            <DeleteDocumentButton params={params} setParams={setParams} />
          </Box>
        ),
      },
    ],
    [dispatch]
  );
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNo: 1,
    sort: "createdAt",
    sortDirection: -1,
  });

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchdocuments(filters),
        (response) => {
          setState(response);

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const getDocumnetList = () => {
    if (
      !settings.document_data ||
      settings.document_data.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(settings.document_filters)
    ) {
      dispatch(fetchDocumentDataAction(filters));
    }
  };
  useEffect(() => {
    fetchList();
    getDocumnetList();
  }, [filters]);

  return (
    <DocumentsUi
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      setState={setState}
      callBack={getDocumnetList}
      loading={settings.document_loading}
      state={settings.document_data}
    />
  );
};
export default DocumentssController;
