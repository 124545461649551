import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { getUserApi, updateUserField } from "../../../apis/user.api"
import { useParams } from "react-router-dom"
import { openModal } from "../../../store/actions/modalAction"
import LeaveSummaryUi from "./LeaveSummaryUi"
import { getCoffApi, getLeavesApi } from "../../../apis/leave.api"
import moment from "moment"
import { LEAVE_STATUS } from "../../../utils/leave.constants"
import CoffViewButton from "./CoffViewButton"
import { findObjectKeyByValue } from "../../../utils/helper"
import { Box, Chip } from "@mui/material"
import { HALF_TYPE_OBJ } from "../../../utils/constants"
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker"


// const CompOffSummaryController = ({ userId }) => {
//     const dispatch = useDispatch()
//     const params = useParams()

//     const columns = useMemo(() => [
//         { id: 1, fieldName: 'createdAt', label: 'Application Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.createdAt??params.date).format("DD/MM/YYYY") },
//         { id: 2, fieldName: 'time_duration', label: 'Time Duration', align: "left", sort: true, minWidth: '150px', renderValue: (params) => params.time_duration==HALF_TYPE_OBJ.FULL_DAY?1:0.5 },
//         {
//             id: 3, fieldName: 'from', label: 'Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => {
//                 let str = moment(params.date).format("DD/MM/YYYY")

//                 return str
//             }
//         },
       
//         {
//             id: 5, fieldName: 'status', label: 'Status', align: "left", sort: true, minWidth: '50px', renderValue: (params, setParams) => <Chip
//                 color={params.status == LEAVE_STATUS.APPROVED ? "success" : (params.status == LEAVE_STATUS.REJECTED ? "error" : "warning")}
//                 label={findObjectKeyByValue(params.status, LEAVE_STATUS)}
//                 size="small"
//             />
//         },
//         {
//             id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <CoffViewButton id={params._id} />
//         },
//     ], [dispatch]);
//     const [list, setList] = useState([])



//     const [listLoading, setListLoading] = useState(false)

    
//     const [filters, setFilters] = useState({
//         page: 1,
//         limit: 10,
//         date: moment(),
//         userId: userId

//     })

//     const fetchList = () => {
//         setListLoading(true)
//         const parmasToPass = { ...filters }
//         parmasToPass.date = parmasToPass.date.valueOf()
//         dispatch(callApiAction(
//             async () => await getCoffApi({ ...parmasToPass }),
//             (response) => {
//                 setList(response)

//                 setListLoading(false)
//             },
//             (err) => {
//                 setListLoading(false)
//             }
//         ))
//     }



//     useEffect(() => {
//         fetchList()
//     }, [filters])



//     return (
//         <>
//             <LeaveSummaryUi
//                 columns={columns}
//                 isFromCoff={true}
//                 listLoading={listLoading}
//                 filters={filters}
//                 setFilters={setFilters}
//                 callBack={fetchList}
//                 list={list}

//             />

//         </>
//     )
// }
// export default CompOffSummaryController

const CompOffSummaryController = ({ userId }) => {
    const dispatch = useDispatch();
    const params = useParams();

    const columns = useMemo(() => [
        { id: 1, fieldName: 'createdAt', label: 'Application Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => moment(params.createdAt ?? params.date).format("DD/MM/YYYY") },
        { id: 2, fieldName: 'time_duration', label: 'Time Duration', align: "left", sort: true, minWidth: '150px', renderValue: (params) => params.time_duration == HALF_TYPE_OBJ.FULL_DAY ? 1 : 0.5 },
        {
            id: 3, fieldName: 'from', label: 'Date', align: "left", sort: true, minWidth: '150px', renderValue: (params) => {
                let str = moment(params.date).format("DD/MM/YYYY");
                return str;
            }
        },
        {
            id: 5, fieldName: 'status', label: 'Status', align: "left", sort: true, minWidth: '50px', renderValue: (params, setParams) => <Chip
                color={params.status == LEAVE_STATUS.APPROVED ? "success" : (params.status == LEAVE_STATUS.REJECTED ? "error" : "warning")}
                label={findObjectKeyByValue(params.status, LEAVE_STATUS)}
                size="small"
            />
        },
        {
            id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <CoffViewButton id={params._id} />
        },
    ], [dispatch]);

    const [list, setList] = useState([]);
    const [listLoading, setListLoading] = useState(false);

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        date: moment(),
        userId: userId
    });

    const fetchList = () => {
        setListLoading(true);
        const parmasToPass = { ...filters };
        parmasToPass.date = parmasToPass.date.toISOString(); 
        dispatch(callApiAction(
            async () => await getCoffApi({ ...parmasToPass }),
            (response) => {
                setList(response);
                setListLoading(false);
            },
            (err) => {
                setListLoading(false);
            }
        ));
    };

    useEffect(() => {
        fetchList();
    }, [filters]);

    return (
        <>
            
            <LeaveSummaryUi
                columns={columns}
                isFromCoff={true}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                callBack={fetchList}
                list={list}
            />
        </>
    );
}

export default CompOffSummaryController;